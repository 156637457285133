<template>
  <div v-if="!isLoading">
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <div class="input-group">
        <span class="input-group-text bg-white">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-start-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search enquiry by name, email, phone or message"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div class="ms-2" style="width: 10rem">
        <select class="form-select" v-model="filterResolved">
          <option :value="null">All</option>
          <option value="new">New</option>
          <option value="resolved">Resolved</option>
        </select>
      </div>
    </div>
    <div class="pb-3 px-3 text-muted small text-end">
      <span class="me-3">
        <i class="far fa-square me-2" />
        {{ enquiries.filter((enquiry) => !enquiry.resolved).length }} New
      </span>
      <span>
        <i class="fas fa-check-square me-2" />
        {{ enquiries.filter((enquiry) => enquiry.resolved).length }} Resolved
      </span>
    </div>
    <div
      class="
        table-responsive
        rounded
        shadow-sm
        rounded
        border border-light
        position-relative
      "
    >
      <table class="table lh-sm table-admin w-100">
        <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>Message</th>
            <th style="min-width: 84px"></th>
            <th class="text-center">Resolved</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="enquiry in computedEnquiries" :key="enquiry._id">
            <td
              class="text-nowrap text-truncate"
              v-html="Helper.formatDate(enquiry.createdAt, true, true)"
            ></td>
            <td class="mw-100 text-nowrap">
              <p class="mb-2">
                {{ enquiry.name }}
                <i
                  class="fas fa-check-circle text-success ms-2"
                  v-if="enquiry.user"
                />
              </p>
              <a
                :href="`mailto:${enquiry.email}`"
                class="
                  d-block
                  mb-1
                  text-dark text-decoration-none
                  small
                  clickable
                "
                v-if="enquiry.email"
              >
                {{ enquiry.email }}
                <i class="fas fa-reply ms-1 text-primary" />
              </a>
              <a
                :href="`https://wa.me/6${enquiry.phone}`"
                target="_blank"
                class="d-block text-dark text-decoration-none small clickable"
                v-if="enquiry.phone"
              >
                {{ enquiry.phone }}
                <i class="fab fa-whatsapp ms-1 text-success" />
              </a>
            </td>
            <td class="mw-100" v-html="enquiry.message"></td>
            <td class="text-nowrap text-center px-0">
              <button
                class="btn btn-sm btn-success px-3 lh-1"
                :class="{ invisible: enquiry.resolved }"
                @click="markAsResolved(enquiry._id)"
              >
                Mark as<br />
                Resolved
              </button>
            </td>
            <td class="text-nowrap text-truncate text-center">
              <i
                class="fas fa-check-square text-success"
                v-if="enquiry.resolved"
              />
              <i class="far fa-square" v-else />
            </td>
          </tr>
          <tr v-if="computedEnquiries.length < 1">
            <td colspan="5" class="text-center fst-italic text-muted">
              No<span> {{ filterResolved }} </span>enquiry found
            </td>
          </tr>
        </tbody>
        <loading-spinner class="position-absolute" v-if="isResolving" />
      </table>
    </div>
  </div>
  <loading-spinner class="position-relative bg-light py-5" v-else>
    Loading enquiries
  </loading-spinner>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import Swal from "sweetalert2";

export default {
  components: { LoadingSpinner },
  data() {
    return {
      isLoading: false,
      isResolving: false,
      enquiries: [],
      search: "",
      filterResolved: "new",
    };
  },
  computed: {
    computedEnquiries() {
      const search = this.search.toLowerCase();

      let result = [...this.enquiries];

      if (this.filterResolved) {
        result = result.filter(
          (enquiry) =>
            enquiry.resolved ==
            (this.filterResolved == "resolved" ? true : false)
        );
      }

      return result.filter(
        (enquiry) =>
          enquiry.name.toLowerCase().includes(search) ||
          enquiry.email.toLowerCase().includes(search) ||
          enquiry.phone.includes(search) ||
          enquiry.message.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    markAsResolved(id) {
      Swal.fire({
        html: "Mark this enquiry as Resolved?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isResolving = true;

          this.API.put(`enquiries/${id}`, { resolved: true })
            .then((retVal) => {
              let enquiry = this.enquiries.find(
                (e) => e._id == retVal.data._id
              );
              enquiry.resolved = retVal.data.resolved;
              Swal.fire("Done", "", "success");
            })
            .catch((err) => {
              Swal.fire(
                "Oops",
                `<p>An error has occured, contact system admin and screenshot this message.</p><p>${err}</p>`,
                "error"
              );
            })
            .finally(() => {
              this.isResolving = false;
            });
        }
      });
    },
    getEnquiries() {
      this.isLoading = true;

      this.API.get("enquiries?_limit=-1&_sort=createdAt:ASC")
        .then((retVal) => {
          this.enquiries = retVal.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getEnquiries();
  },
};
</script>